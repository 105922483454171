import React, { memo } from 'react';

import { InputGroup } from 'reactstrap';

import AsyncSelect from 'react-select/async';

import debounce from 'lodash.debounce';

import api from '~/services/api';

import util from '~/assets/util';

function CategorySelect({ onChange, setRef = null, ...props }) {
  const searchCategories = async (query) => {
    try {
      const res = await api.get(`dashboard/categories/search?q=${query}`);

      const { categories } = res?.data;

      if (!categories) {
        return [];
      }

      return categories;
    } catch (e) {
      console.error(e);
    }
  };

  const getOptionLabel = (category) => {
    let label = category.name;

    if (category.parent) {
      label += ` (${category.parent.name})`;
    }

    return label;
  };

  const loadCategories = (query) =>
    new Promise((resolve) => {
      resolve(searchCategories(query));
    });

  const debouncedLoadCategories = debounce((query, callback) => {
    query.length > 2 && loadCategories(query).then((options) => callback(options));
  }, 1000);

  return (
    <>
      <InputGroup>
        <AsyncSelect
          className="w-100"
          ref={setRef}
          placeholder={util.t('SEARCH_THREE_DOTS')}
          getOptionValue={(option) => option.category_id}
          getOptionLabel={getOptionLabel}
          loadingMessage={() => util.t('SEARCHING_THREE_DOTS')}
          loadOptions={debouncedLoadCategories}
          {...props}
          isClearable
          noOptionsMessage={() => util.t('NO_OPTION')}
          onChange={onChange}
          required
        />
      </InputGroup>
    </>
  );
}

export default memo(CategorySelect);
