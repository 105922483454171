import { OrderStatus, PaymentMethods } from '~/assets/constants';

import util from '~/assets/util';

const service = {
  getStatus(code: OrderStatus) {
    switch (code) {
      case OrderStatus.STATUS_PENDING_PAYMENT:
        return { color: 'warning', label: util.t('STATUS_PENDING_PAYMENT') };
      case OrderStatus.STATUS_CONFIRMED_PAYMENT:
        return { color: 'info', label: util.t('STATUS_CONFIRMED_PAYMENT') };
      case OrderStatus.STATUS_PROCESSING:
        return { color: 'info', label: util.t('STATUS_PROCESSING') };
      case OrderStatus.STATUS_SHIPPED:
        return { color: 'success', label: util.t('STATUS_SHIPPED') };
      case OrderStatus.STATUS_COMPLETED:
        return { color: 'success', label: util.t('STATUS_COMPLETED') };
      case OrderStatus.STATUS_CANCELED:
        return { color: 'danger', label: util.t('STATUS_CANCELED') };
      case OrderStatus.STATUS_REFUNDED:
        return { color: 'info', label: util.t('STATUS_REFUNDED') };
    }
  },

  getPaymentMethod(slug: PaymentMethods): string {
    switch (slug) {
      case PaymentMethods.PAYMENT_METHOD_BOLETO:
        return util.t('BOLETO');
      case PaymentMethods.PAYMENT_METHOD_ONLINE_DEBIT:
        return util.t('ONLINE_DEBIT');
      case PaymentMethods.PAYMENT_METHOD_CREDIT_CARD:
      case PaymentMethods.PAYMENT_METHOD_CREDIT_CARD_MACHINE:
        return util.t('CREDIT_CARD');
      case PaymentMethods.PAYMENT_METHOD_CASH:
        return util.t('CASH');
      case PaymentMethods.PAYMENT_METHOD_PIX:
        return 'PIX';
    }
  },
};

export default service;
