import React, { memo } from 'react';

import { Row, FormGroup, Input, Label, Col, Form } from 'reactstrap';

import Button from '~/components/Button';
import InputCpf from '~/components/InputCpf';

import util from '~/assets/util';
import { Roles } from '~/assets/constants';

import './index.scss';

function Filters({ filters, setFilters, onSave, onClear }) {
  const doSubmit = (ev) => {
    ev.preventDefault();

    onSave();
  };

  const handleChange = (ev) => {
    const name = ev.target.name;

    setFilters({ ...filters, [name]: ev.target.value });
  };

  return (
    <Form onSubmit={doSubmit}>
      <Row form>
        <FormGroup className="col-sm-6 col-lg-5 col-xl-4">
          <Label htmlFor="filter_name">{util.t('NAME')}</Label>
          <Input id="filter_name" name="name" type="text" onChange={handleChange} value={filters.name} />
        </FormGroup>

        <FormGroup className="col-sm-6 col-lg-4 col-xl-4">
          <Label htmlFor="filter_email">{util.t('EMAIL')}</Label>
          <Input id="filter_email" name="email" type="text" onChange={handleChange} value={filters.email} />
        </FormGroup>

        <FormGroup className="col-sm-6 col-lg-3 col-xl-2">
          <Label htmlFor="filter_cpf">{util.t('LABEL_CPF')}</Label>
          <InputCpf className="form-control cpf" id="filter_cpf" name="cpf" onChange={handleChange} value={filters.cpf} />
        </FormGroup>
      </Row>

      <Row form>
        <Col>
          <Button type="submit" color="primary">
            {util.t('SEARCH')}
          </Button>
          <Button className="ml-1" onClick={() => onClear()} type="button" color="secondary">
            {util.t('CLEAR')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default memo(Filters);
