import api from './api';
import store from '~/store';

import { Types } from '~/store/constants';

const service = {
  get: async () => {
    try {
      const { data } = await api.get('tenants');
      return data;
    } catch (e) {
      console.error(e);
    }

    return {};
  },
  save: (tenant: string) => {
    store.dispatch({ type: Types.TENANT_SET, tenant });
  },
};

export default service;
