import React from 'react';

import { NavLink } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import { FaListAlt, FaStar, FaStore, FaTags, FaUserFriends } from 'react-icons/fa';

import util from '~/assets/util';
import { Roles } from '~/assets/constants';

import './index.scss';

function Sidebar({ collapsed, setCollapsed }) {
  const user = useSelector((state: any) => state.user);

  const isActive = (route) => {
    const pathname = window.location.pathname;
    const pathSplit = pathname.split('/');

    if (pathname == route) {
      return true;
    }

    if (pathSplit[1] == route.replace('/', '')) {
      return true;
    }
  };

  const RestrictedMenuItems = () => {
    if (user?.data?.role !== Roles.DASHBOARD) {
      return null;
    }

    return (
      <>
        <MenuItem active={isActive('/stores')} icon={<FaStore size={18} />}>
          <NavLink to="/stores">{util.t('STORES')}</NavLink>
        </MenuItem>
        <MenuItem active={isActive('/users')} icon={<FaUserFriends size={18} />}>
          <NavLink to="/users">{util.t('USERS')}</NavLink>
        </MenuItem>
        <MenuItem active={isActive('/marketplace-categories')} icon={<FaTags size={18} />}>
          <NavLink to="/marketplace-categories">{util.t('MARKETPLACE_CATEGORIES')}</NavLink>
        </MenuItem>
        <MenuItem active={isActive('/import-best-sellers')} icon={<FaStar size={18} />}>
          <NavLink to="/import-best-sellers">{util.t('BEST_SELLERS')}</NavLink>
        </MenuItem>
      </>
    );
  };

  return (
    <div className="navbar-container">
      <ProSidebar
        width={240}
        breakPoint="md"
        toggled={!collapsed}
        collapsed={collapsed}
        onToggle={() => {
          setCollapsed(!collapsed);
        }}>
        <Menu iconShape="square">
          <li className="menu-title">{util.t('MENU')}</li>
          <MenuItem active={isActive('/orders')} icon={<FaListAlt size={18} />}>
            <NavLink to="/orders">{util.t('ORDERS')}</NavLink>
          </MenuItem>
          <RestrictedMenuItems />
        </Menu>
      </ProSidebar>
    </div>
  );
}

export default Sidebar;
