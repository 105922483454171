import { Types } from '~/store/constants';

const INITIAL_STATE = {};

export default function user(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case Types.TENANT_SET:
      return { ...state, ...action.tenant };
    default:
      return state;
  }
}
