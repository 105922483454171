import React, { useEffect, useState } from 'react';

import { Button, Card, CardBody, CardHeader, Modal } from 'reactstrap';
import { useParams } from "react-router-dom";

import Form from './Form';
import UsersTable from '~/components/UsersTable';
import Pagination from '~/components/Pagination';
import Filters from './Filters';

import api from '~/services/api';

import util from '~/assets/util';

function Users() {
  let { id } = useParams();

  const DEFAULT_FILTERS = {
    role: '',
    name: '',
    email: '',
    cpf: '',
    tenant_id: id,
  };

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);

  const [filters, setFilters]: any = useState(DEFAULT_FILTERS);
  const [userId, setUserId] = useState(null);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    try {
      setLoading(true);

      const res = await api.get(`dashboard/users?page=${page}`, { params: filters });

      let { users } = res.data;

      users.links?.shift();
      users.links?.splice(users.links.length - 1, 1);

      setUsers(users);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const newUser = () => {
    setUserId(0);
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <header className="row mt-3">
        <div className="col-12">
          <div className="card p-3">
            <Filters
              filters={filters}
              setFilters={setFilters}
              onSave={doSearch}
              onClear={() => {
                setFilters(DEFAULT_FILTERS);
              }}
            />
          </div>
        </div>
      </header>

      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('USERS')}</h1>
            </header>

            <div className="text-right">
              <Button onClick={newUser} className="my-3" color="primary" outline>
                {util.t('ADD')}
              </Button>
            </div>

            <UsersTable
              onOpen={(id) => {
                setUserId(id);
              }}
              loading={loading}
              users={users}
              doUpdate={doSearch}
            />

            <div className="d-flex justify-content-end mt-4">{users && <Pagination onChange={(page) => doSearch(page)} {...users} />}</div>
          </div>
        </section>

        <Modal autoFocus={false} isOpen={userId != null} toggle={() => setUserId(null)} size="xl">
          <Card>
            <CardHeader>{util.t('USER')}</CardHeader>
            <CardBody>
              <Form
                id={userId}
                tenant_id={id}
                onClose={() => {
                  setUserId(null);
                }}
                onSave={() => {
                  doSearch();
                }}
              />
            </CardBody>
          </Card>
        </Modal>
      </main>
    </>
  );
}

export default Users;
