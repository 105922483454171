export default {
  ACTION: 'Ação',
  ACTIVE: 'Ativo',
  ADD: 'Adicionar',
  ADDRESS_CITY: 'Cidade',
  ADDRESS_COMPLEMENT: 'Complemento',
  ADDRESS_NEIGHBORHOOD: 'Bairro',
  ADDRESS_NUMBER: 'N°',
  ADDRESS_STREET: 'Rua',
  ADDRESS_UF: 'UF',
  ADDRESS_ZIPCODE: 'CEP',
  ADDRESS: 'Endereço',
  ADMIN_EMAIL_ALREADY_EXISTS: 'Esse email já é usado por um administrador.',
  ADMIN: 'Administrador',
  ALL: 'Todos',
  ANALYTICS_WIDGETS: 'Widgets',
  ANY: 'Qualquer',
  ANY_LEVEL: 'Qualquer nível',
  APPLIED_COUPON: 'Cupom aplicado',
  AUTHOR: 'Autor',
  AUTHORS: 'Autores',
  BANNER_ALREADY_EXISTS_IN_POSITION: 'Já existe um banner cadastrado nessa posição.',
  BANNER_SAVED: 'Banner salvo com sucesso.',
  BANNER: 'Banner',
  BANNERS: 'Banners',
  BEST_SELLERS: 'Mais Vendidos',
  BIRTHDATE: 'Data de nascimento',
  BOLETO: 'Boleto',
  BRAND: 'Editora',
  BRANDS: 'Editoras',
  CANCEL_ORDER: 'Cancelar pedido',
  CANCEL_THIS_ORDER: '',
  CANCEL: 'Cancelar',
  CANNOT_CANCEL_THIS_ORDER_NF: 'Não é possível cancelar esse pedido, a Nota Fiscal já foi emitida.',
  CANNOT_CANCEL_THIS_ORDER: 'Não é possível cancelar esse pedido.',
  CANNOT_REFUND_THIS_ORDER: 'Não foi possível reembolsar esse pedido.',
  CANNOT_SET_THIS_STATUS: 'Não é possível alterar o status desse pedido para o status selecionado.',
  CASH: 'Dinheiro',
  CATALOGUE_IMPORT_SUCCESS: 'Os produtos foram atualizados.',
  CATEGORY_SAVED: 'A categoria foi salva com sucesso.',
  CELL_PHONE: 'Celular',
  CHANGE_STATUS: 'Alterar status',
  CHANGES_HISTORY: 'Histórico de alterações',
  CHILDREN: 'Filhas',
  CITY_STATE: 'Cidade/UF',
  CLEAR: 'Limpar',
  CODE_ALREADY_EXISTS: 'Esse código já está cadastrado.',
  CODE_REQUIRED: 'O código é obrigatório',
  CODE: 'Código',
  COMPANY_NAME: 'Razão social',
  CONFIRM_CANCEL_ORDER: 'Tem certeza que deseja cancelar esse pedido?',
  CONFIRM_DELETE: 'Tem certeza que deseja apagar esse registro?',
  CONFIRM_RESEND_ORDER: 'Enviar novamente o pedido para o WinBooks?',
  CONFIRM: 'Confirmar',
  CONTACT_MAIL: 'Email de contato',
  COUPON_SAVED: 'Cupom salvo com sucesso',
  COUPON: 'Cupom',
  COUPONS: 'Cupons',
  CPF_ALREADY_EXISTS: 'Esse CPF já está cadastrado.',
  CPF_CNPJ: 'CPF/CNPJ',
  CREATE_DISCOUNT: 'Criar desconto',
  CREDIT_CARD: 'Cartão de crédito',
  CUSTOMER_DATA: 'Dados do cliente',
  CUSTOMER_EMAIL: 'Email do cliente',
  CUSTOMER_NAME: 'Nome do cliente',
  CUSTOMER: 'Cliente',
  DASHBOARD_CATAVENTO: 'Catavento',
  DASHBOARD: 'Super administrador',
  DATE: 'Data',
  DESCRIPTION: 'Descrição',
  DISCOUNT_CREATED_ERROR: 'Já existe uma regra ativa para esta Editora/Autor.',
  DISCOUNT_CREATED: 'Desconto aplicado com sucesso.',
  DISCOUNT_DELETED: 'Desconto removido com sucesso.',
  DISCOUNT_PERCENTAGE: '% de desconto',
  DISCOUNT_TYPE: 'Tipo de desconto',
  DISCOUNT: 'Desconto',
  DISTRIBUTION_CENTER_ABBRV: 'C.D',
  DISTRIBUTION_CENTER: 'Centro de distribuição',
  DRAG_IMAGE: 'Arraste uma imagem ou clique no botão abaixo',
  DROP_FILE: 'Solte a imagem aqui',
  EDIT: 'Editar',
  EDITED_VALUE: 'Valor editado',
  EMAIL_ALREADY_EXISTS: 'Já existe um usuário cadastrado com este email.',
  EMAIL_PAGSEGURO: 'E-mail PagSeguro',
  EMAIL: 'E-mail',
  EXPIRATION_DATE: 'Data de expiração',
  EXPIRED_AT: 'Expirado em',
  FEMALE: 'Feminino',
  FILE_TOO_LARGE: 'O arquivo é muito grande.',
  FILE: 'Arquivo',
  FORGOT_PASSWORD: 'Esqueci a senha',
  FREE_SHIPPING: 'Frete Grátis',
  FROM: 'De',
  FULL_NAME: 'Nome completo',
  GENDER: 'Sexo',
  GENERAL_INFORMATION_SAVED: 'Informações editadas com sucesso.',
  GENERAL_INFORMATION: 'Informações gerais',
  GOOGLE_ANALYTCS: 'Google Analytics',
  GOOGLE_TAGMANAGER: 'Google Tag Manager',
  HOME: 'Home',
  IMAGE_REQUIRED: 'A imagem é obrigatória.',
  IMAGE_SECTION: 'Seções de imagens',
  IMAGE: 'Imagem',
  IMPORT_BEST_SELLERS_CATALOGUE: 'Importar os produtos mais vendidos a partir de uma planilha da Veja.',
  IMPORT: 'Importar',
  INACTIVE: 'Inativo',
  INTERNAL: 'Internas',
  TOTAL_LINKED_CATEGORIES: 'Total de categorias vinculadas',
  INVALID_BIRTHDATE: 'A data de nascimento informada é inválida.',
  INVALID_CELL_PHONE: 'O número de celular é inválido. Lembre-se de incluir o DDD.',
  INVALID_CPF_FORMAT: 'Formato do CPF inválido.',
  INVALID_CPF: 'CPF inválido',
  INVALID_EMAIL: 'Email inválido.',
  INVALID_EXPIRE_AT_DATE: 'A data de expiração inserida é inválida.',
  INVALID_FACEBOOK_PIXEL: 'O código de identifação do Facebook Pixel é inválido',
  INVALID_GOOGLE_ANALYTICS: 'O código de acompanhamento do Google Analytics é inválido',
  INVALID_IMAGE: 'O arquivo precisa ser uma imagem',
  INVALID_PHONE: 'Telefone inválido. Lembre-se de incluir o DDD.',
  INVALID_SPREAD_SHEET: 'Planilha não reconhecida.',
  INVALID_TAGMANAGER: 'O código do Google Tag Manager é inválido',
  INVOICE_NUMBER: 'Número da Nota Fiscal',
  INVOICE_NUMBER_ALREADY_ISSUED: 'Não foi possível cancelar o pedido, pois a nota fiscal já foi emitida.',
  ISBN: 'ISBN',
  JURIDICAL_PERSON: 'Pessoa jurídica',
  LABEL_CNPJ: 'CNPJ',
  LABEL_CPF: 'CPF',
  LANDLINE: 'Telefone fixo',
  LEAVE_HOST_FIELD_EMPTY: 'Deixe em branco para usar o servidor padrão.',
  LEVEL: 'Nível',
  LINK_ACTIVE_URL: 'Por favor, verifique se o link está ativo',
  LINK: 'Link',
  LINKED_CATEGORIES: 'Categorias vinculadas',
  LOADING: 'Carregando',
  LOGIN: 'Login',
  LOGO: 'Logo',
  MARKETPLACE: 'Marketplace',
  MAIL_HOST: 'Host',
  MAIL_PORT: 'Porta',
  MAIN_ORDER_NUMBER: 'Ordem de Compra',
  MALE: 'Masculino',
  MARKETPLACE_CATEGORIES: 'Categorias',
  MARKETPLACE_CATEGORY: 'Categoria no marketplace',
  MENU: 'Menu',
  MIN_VALUE: 'Valor mínimo',
  MODEL: 'Modelo',
  NAME_MUST_BE_FULL_NAME: 'Insira o nome completo.',
  NAME: 'Nome',
  NATURAL_PERSON: 'Pessoa física',
  NEIGHBORHOOD: 'Bairro',
  NO_BOOKS_IMPORTED: 'Nenhum livro foi importado.',
  NO_OPTION: 'Nenhuma opção',
  NO_RECORDS_FOUND: 'Nenhum registro encontrado',
  NO_STORES_FOUND: 'Nenhuma loja encontrada.',
  NO_TENANTS_FOUND: 'Nenhuma loja encontrada.',
  NOTE: 'Observação',
  ONLINE_DEBIT: 'Débito online',
  OPENING_HOURS: 'Horário de funcionamento',
  ORDER_CANCEL_SUCCESS: 'O pedido foi cancelado com sucesso.',
  ORDER_DATA: 'Dados do pedido',
  ORDER_ITEMS: 'Itens do pedido',
  ORDER_NOT_FOUND: 'Pedido não encontrado',
  ORDER_NUMBER: 'Número do pedido',
  ORDER_RESUME: 'Resumo da ordem de compra',
  ORDER_STATUS_CHANGE_SUCCESS: 'O status do pedido foi alterado com sucesso.',
  ORDER_WILL_BE_SENT: 'O pedido será enviado novamente',
  ORDER: 'Pedido',
  ORDERS_RECEIVED: 'Total de pedidos',
  ORDERS: 'Pedidos',
  ORIGIN: 'Origem',
  ORIGINAL_VALUE: 'Valor original',
  PAGSEGURO_CANCEL_ERROR: 'Ocorreu um erro ao cancelar o pedido no PagSeguro.',
  PASSWORD: 'Senha',
  PAYMENT_CONFIRMED_AT: 'Pagamento confirmado em',
  PAYMENT_METHOD: 'Método de pagamento',
  PAYMENT: 'Pagamento',
  PENDING: 'Pendente',
  PERCENT: 'Porcentagem',
  PERMISSION_DENIED: 'Permissão negada',
  PIXEL_FACEBOOK: 'Pixel Facebook',
  POSITION_REQUIRED: 'A posição é obrigatória.',
  POSITION_UNAVAILABLE_ERROR: 'A posição escolhida já está sendo utilizada por outra seção!',
  POSITION: 'Posição',
  PREVIEW: 'Prévia',
  PRODUCT_SAVED: 'Produto salvo com suceso.',
  PRODUCT: 'Produto',
  PRODUCTS: 'Produtos',
  QUANTITY_STOCK: 'Qtd. estoque',
  QUANTITY: 'Quantidade',
  RECEIVER: 'Destinatário',
  REDE_REFUND_REQUESTED: 'O cancelamento foi solicitado na Rede e o processamento deve ocorrer no próximo dia útil.',
  REFUND_AMOUNT: 'Reembolsado',
  REFUND_ID_NOT_FOUND: 'Reembolso não encontrado.',
  REFUND_NOT_ALLOWED_CHARGEBACK_REQUESTED: 'Reembolso não permitido. Chargeback solicitado.',
  REFUND_NOT_ALLOWED: 'Reembolso não permitido.',
  REFUND_NOT_FOUND: 'Reembolso não encontrado.',
  REFUND_ORDER_ADVICE: 'Esse pedido já foi pago e o valor será estornado para o cliente.',
  REGISTER_AT: 'Registrado em',
  REMOVE: 'Remover',
  REQUEST_FAILED_CONTACT_REDE: 'Falha na requisição. Entre em contato com a Rede.',
  ROOT: 'Raiz',
  ROOT_CATEGORY: 'Categoria Raiz',
  SAVE: 'Salvar',
  SAVED_SUCCESSFULLY: 'Salvo com sucesso',
  SEARCH_THREE_DOTS: 'Buscar...',
  SEARCH: 'Buscar',
  SEARCHING_THREE_DOTS: 'Buscando...',
  SECTION_SAVED: 'Seção salva com sucesso.',
  SELECT_FILE: 'Selecionar arquivo',
  SELECT: 'Selecione',
  SENT_AT: 'Enviado em',
  SETTINGS: 'Configurações',
  SHIPPING_CODE: 'Código de rastreamento',
  SHIPPING_DATA: 'Dados da entrega',
  SHIPPING_METHOD: 'Método de envio',
  SHIPPING: 'Frete',
  SLUG: 'Slug',
  STATE_REGISTRY_NUMBER: 'Inscrição estadual',
  STATUS_CANCELED: 'Cancelado',
  STATUS_COMPLETED: 'Finalizado',
  STATUS_CONFIRMED_PAYMENT: 'Pagamento Confirmado',
  STATUS_PENDING_PAYMENT: 'Aguardando Pagamento',
  STATUS_PROCESSING: 'Processando',
  STATUS_REFUNDED: 'Reembolsado',
  STATUS_SHIPPED: 'Enviado',
  STATUS: 'Status',
  STORE_DELETED: 'Loja removida com sucesso.',
  STORE_NAME: 'Nome da Loja',
  STORE_NOT_FOUND: 'Loja inexistente.',
  STORE_ORDER_STATUS_WARNING:
    'Confirmar o pagamento ou cancelar o pedido terá efeito nos outros pedidos da ordem de compra. Deseja alterar o status de todos os pedidos?',
  STORE: 'Loja',
  STORES: 'Lojas',
  SUBTOTAL: 'Subtotal',
  SUCCESSFULLY_IMPORTED_CPF: "CPF's importados com sucesso",
  SUM_OF_REFUNDS_GREATER_THAN_THE_TRANSACTION_AMOUNT: 'Soma dos reembolsos maior que o valor da transação.',
  SUM_OF_REFUNDS_GREATER_THAN_THE_VALUE_PROCESSED_AVAILABLE_FOR_REFUND:
    'Soma dos reembolsos maior que o valor processado disponível para reembolso.',
  TITLE_REQUIRED: 'O título é obrigatório',
  TITLE: 'Título',
  TO: 'Até',
  TOKEN_HAS_EXPIRED: 'Sua sessão expirou.',
  TOKEN_PAGSEGURO: 'Token PagSeguro',
  TOTAL_SALES: 'Valor total de vendas',
  TOTAL: 'Total',
  TRANSACTION_ALREADY_CANCELED: 'Transação já cancelada.',
  TRANSACTION_DOES_NOT_EXIST: 'Transação não encontrada.',
  TRANSACTION_NOT_AVAILABLE_FOR_REFUND_TRY_AGAIN_IN_A_FEW_HOURS:
    'Transação não disponível para reembolso. Tente novamente em algumas horas.',
  TRANSACTION_NOT_FOUND: 'Transação não encontrada.',
  TRANSACTION_WITH_PERIOD_EXPIRED_FOR_REFUND: 'Transação com período expirado para reembolso.',
  TRANSACTIONAL_MAILS: 'Emails transacionais',
  TRANSITION_TIME: 'Tempo de transição',
  TYPE: 'Tipo',
  UNAUTHORIZED: 'Permissão negada.',
  UNIT_PRICE: 'Preço unitário',
  UNLIMITED: 'Ilimitado',
  UPDATE_BEST_SELLERS: 'Atualizar Mais Vendidos',
  UPDATE: 'Atualizar',
  UPLOAD_LOGO_ERROR: 'Ocorreu um erro ao salvar a imagem. Por favor, tente novamente.',
  URL_FACEBOOK: 'URL Facebook',
  URL_INSTAGRAM: 'URL Instagram',
  URL: 'URL',
  USAGE_LIMIT: 'Limite de usos',
  USE_DEFAULT_EMAIL: 'Usar email personalizado',
  USER_DELETED: 'Usuário removido com sucesso.',
  USER_NOT_FOUND: 'Usuário inexistente.',
  USER_ROLE: 'Tipo de usuário',
  USER_SAVED: 'Usuário salvo com sucesso.',
  USER: 'Usuário',
  USERS: 'Usuários',
  VALID_UNTIL: 'Válido até',
  VALIDITY: 'Validade',
  VALUE: 'Valor',
  VEJA_SPREADSHEET: 'Planilha (VEJA)',
  WAITING_WINBOOKS_ORDER: 'Aguardando o envio do pedido',
  WARNING: 'Atenção',
  WINBOOKS_CREDENTIALS: 'Credenciais WinBooks',
  WINBOOKS_ORDER_DATA: 'Pedido no WinBooks',
  WRONG_CREDENTIALS: 'Senha incorreta.',
  ZIPCODE: 'CEP',
  ORDER_HAS_NO_REGISTERED_CHARGE_YET: 'Não é possível cancelar esse pedido no momento, não existe o registro de cobrança.',
  SCHOOL_LIST: 'Lista escolar',
  CLIENT_DROP: 'Cliente Drop',
  INTEGRATION_WINDBOOKS: 'Integração Windbooks',
};
