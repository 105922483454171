import React, { memo, useEffect, useState } from 'react';

import { Col, Row, Spinner } from 'reactstrap';

import OrderData from '../OrderData';
import OrderItems from '../OrderItems';
import UserResume from '../UserResume';
import ShippingResume from '../ShippingResume';
import WinbooksResume from '../WinbooksResume';
import SendOrderButton from '../SendOrderButton';

import api from '~/services/api';

import notification from '~/services/notification';

import { CouponType } from '~/assets/constants';

import util from '~/assets/util';

function OrderDetails({ onChange = null, id = null }) {
  const [loading, setLoading] = useState(false);
  const [refreshOrder, setRefreshOrder] = useState(false);
  const [storeOrder, setStoreOrder] = useState(null);
  const [school, setSchool] = useState(null);

  useEffect(() => {
    let isMounted = true;

    async function loadOrder() {
      if (!isMounted) {
        return false;
      }

      try {
        setLoading(true);

        const res = await api.get(`dashboard/orders/${id}`);

        const data = res?.data;

        if (data.order) {
          const order = data.order;

          setStoreOrder(order);
        }

        if (data?.school) {
          setSchool(data?.school?.name);
        }
      } catch (e) {
        notification.$e(e);
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    loadOrder();

    return function () {
      isMounted = false;
    };
  }, [refreshOrder]);

  const order = storeOrder?.order;

  return (
    <>
      {storeOrder && !loading && (
        <section>
          <Row>
            <Col lg="6" className="mb-3">
              <h1 className="h4">{util.t('ORDER_DATA')}</h1>
              <OrderData
                onChange={(value) => {
                  setStoreOrder(value);
                  onChange(value);
                }}
                storeOrder={storeOrder}
              />
            </Col>
            <Col lg="6">
              <h1 className="h4">{util.t('CUSTOMER_DATA')}</h1>
              <UserResume user={order.user} />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xl="6" className="mb-3">
              <h1 className="h4">
                {util.t('WINBOOKS_ORDER_DATA')}
                {order.tenant_id != 35 ? <SendOrderButton className="float-right" storeOrder={storeOrder} /> : null}

                <hr />
              </h1>

              <WinbooksResume storeOrder={storeOrder} />
            </Col>

            <Col xl="6">
              <h1 className="h4">{util.t('SHIPPING_DATA')}</h1>
              <hr />

              <ShippingResume storeOrder={storeOrder} setRefreshOrder={setRefreshOrder} />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <h1 className="h4">{util.t('ORDER_ITEMS')}</h1>
              <hr />

              <OrderItems items={storeOrder.items} />
            </Col>
          </Row>

          <footer className="text-right">
            <p>
              {util.t('SUBTOTAL')}: <strong>R$ {util.format(storeOrder.subtotal)}</strong>
            </p>

            <p>
              {util.t('SHIPPING')}: <strong>R$ {util.format(storeOrder.shipping_rate)}</strong>
            </p>

            <p>
              {util.t('TOTAL')}: <strong>R$ {util.format(storeOrder.gross_amount)}</strong>
            </p>
            {storeOrder.refund_amount && (
              <p>
                {util.t('REFUND_AMOUNT')}: <strong>R$ {util.format(storeOrder.refund_amount)}</strong>
              </p>
            )}

            <section className="mt-5">
              <h2>{util.t('ORDER_RESUME')}</h2>
              <hr />
              <p>
                {util.t('SUBTOTAL')}: <strong>R$ {util.format(order.subtotal)}</strong>
              </p>

              <p>
                {util.t('SHIPPING')}:{' '}
                <strong>{order.coupon_type == CouponType.FREE_SHIPPING ? util.t('FREE') : `R$ ${util.format(order.shipping_rate)}`}</strong>
              </p>
              {order.coupon_code && (
                <>
                  {/* {(order.tenant_id == 18) &&
                    <p>
                      {util.t('SCHOOL')}: <strong> {school}</strong>
                    </p>
                  } */}
                  <p>
                    {util.t('APPLIED_COUPON')}: <strong> {order.coupon_code}</strong>
                  </p>
                  <p>
                    {util.t('DISCOUNT')}:<strong>R$ {util.format(storeOrder.discount)}</strong>
                    {order.coupon_type == CouponType.FREE_SHIPPING && util.t('SHIPPING')}
                  </p>
                </>
              )}
              <p>
                {util.t('TOTAL')}: <strong>R$ {util.format(order.gross_amount)}</strong>
              </p>
            </section>
          </footer>
        </section>
      )}
      {loading && <Spinner color="primary" className="d-block m-auto" />}
    </>
  );
}

export default memo(OrderDetails);
