import React, { memo, useState } from 'react';

import { Button, Spinner, UncontrolledTooltip } from 'reactstrap';

import { FaBan } from 'react-icons/fa';

import Swal from 'sweetalert2';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import { OrderStatus } from '~/assets/constants';

function CancelOrderButton({ order, onCancel = null }) {
  const id = `btn-cancel-${order.store_order_id}`;

  const disabled = !order.can_cancel;

  const [loading, setLoading] = useState(false);

  const showModal = () => {
    const showRefundAdvice = order.status != OrderStatus.STATUS_PENDING_PAYMENT;

    const text = `${showRefundAdvice ? util.t('REFUND_ORDER_ADVICE') : ''} ${util.t('CONFIRM_CANCEL_ORDER')}`;

    Swal.fire({
      html: text,
      showCancelButton: true,
      cancelButtonText: util.t('CANCEL'),
      confirmButtonText: util.t('CONFIRM'),
      icon: 'warning',
      confirmButtonColor: '#dc3545',
      iconColor: '#dc3545',
      focusCancel: true,
    }).then((result) => {
      result.isConfirmed && doCancel();
    });
  };

  const doCancel = async () => {
    if (loading) {
      return false;
    }

    setLoading(true);

    try {
      const { data } = await api.post(`dashboard/orders/cancel/${order.store_order_id}`);

      if (data.store_orders) {
        onCancel(data.store_orders);
      }

      return notification.$s(util.t(data.message));
    } catch (e) {
      console.error('CancelOrderButton.doCancel', e);

      notification.$e(e);
    } finally {
      setLoading(false);
    }
  };

  const renderIcon = () => {
    if (loading) {
      return <Spinner color="danger" size="sm" />;
    }

    return (
      <>
        <FaBan id={id} />

        <UncontrolledTooltip target={id} placement="top">
          {util.t(disabled ? (order.status == OrderStatus.STATUS_REFUNDED || order.status == OrderStatus.STATUS_CANCELED ? 'CANNOT_CANCEL_THIS_ORDER' : 'CANNOT_CANCEL_THIS_ORDER_NF') : 'CANCEL_ORDER')}
        </UncontrolledTooltip>
      </>
    );
  };

  return (
    <Button disabled={disabled} size="sm" outline color="danger" type="button" onClick={showModal}>
      {renderIcon()}
    </Button>
  );
}

export default memo(CancelOrderButton);
