import React, { useState } from 'react';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import InternalTab from './InternalTab';
import MarketplaceTab from './MarketplaceTab';

import util from '~/assets/util';

function MarketplaceCategories() {
  const [activeTab, setActiveTab] = useState('internal');

  return (
    <>
      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header className="mb-3">
              <h1 className="h2 m-0">{util.t('MARKETPLACE_CATEGORIES')}</h1>
            </header>

            <Nav tabs>
              <NavItem>
                <NavLink className={`cursor-pointer ${activeTab === 'internal' ? 'active' : ''}`} onClick={() => setActiveTab('internal')}>
                  {util.t('INTERNAL')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`cursor-pointer ${activeTab === 'marketplace' ? 'active' : ''}`}
                  onClick={() => setActiveTab('marketplace')}>
                  {util.t('MARKETPLACE')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane className="pt-2" tabId={'internal'}>
                <InternalTab />
              </TabPane>
              <TabPane className="pt-2" tabId={'marketplace'}>
                <MarketplaceTab />
              </TabPane>
            </TabContent>
          </div>
        </section>
      </main>
    </>
  );
}

export default MarketplaceCategories;
