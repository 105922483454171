import React, { memo } from 'react';

import { OrderStatus } from '~/assets/constants';
import util from '~/assets/util';

const statuses = Object.entries(OrderStatus);

function StatusSelect(props) {
  return (
    <>
      <select className="form-control" {...props}>
        <option value="">{util.t('ALL')}</option>
        {statuses.map((status) => {
          const id = status[1];
          const label = status[0];

          return (
            <option key={id} value={id}>
              {util.t(label)}
            </option>
          );
        })}
      </select>
    </>
  );
}

export default memo(StatusSelect);
