import React from 'react';

import util from '~/assets/util';
import Form from './Form';

function ImportBestSellers() {
  return (
    <>
      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header className="mb-3">
              <h1 className="h2 m-0">{util.t('BEST_SELLERS')}</h1>
              <p>{util.t('IMPORT_BEST_SELLERS_CATALOGUE')}</p>
            </header>

            <div className="row">
              <Form />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ImportBestSellers;
