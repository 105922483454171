import React, { memo, useEffect, useState } from 'react';

import { Card, CardBody, CardHeader, Modal as BModal, Form, FormGroup, Label, Row } from 'reactstrap';

import Button from '~/components/Button';
import CategorySelect from '~/components/CategorySelect';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

function Modal({ onClose, onSave, category, isOpen = false }) {
  const [saving, setSaving] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    if (!category) {
      return;
    }

    setSelectedCategories(category.internal_categories);
  }, [category]);

  const save = async (ev) => {
    ev.preventDefault();

    setSaving(true);

    const url = `dashboard/marketplace/categories/${category.marketplace_category_id}/edit`;

    const categoriesIds = selectedCategories.map((category) => category.category_id);

    try {
      await api.post(url, {
        internal_categories: categoriesIds,
      });

      notification.$s(util.t('SAVED_SUCCESSFULLY'));

      onSave();
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const Body = () => {
    if (!isOpen) {
      return;
    }

    return (
      <>
        <Form onSubmit={save}>
          <Row form>
            <FormGroup className="col-12">
              <Label>{util.t('LINKED_CATEGORIES')}</Label>
              <CategorySelect
                name="marketplace_categories"
                value={selectedCategories}
                setRef={() => 1}
                onChange={(value) => setSelectedCategories(value)}
                isMulti
              />
            </FormGroup>
          </Row>

          <Row form>
            <FormGroup className="col-12">
              <Button color="primary" type="submit" loading={saving}>
                {util.t('SAVE')}
              </Button>
            </FormGroup>
          </Row>
        </Form>
      </>
    );
  };

  return (
    <BModal autoFocus={false} isOpen={isOpen} size="lg" toggle={onClose} unmountOnClose>
      <Card>
        <CardHeader>{category?.name}</CardHeader>
        <CardBody>{isOpen && <Body />}</CardBody>
      </Card>
    </BModal>
  );
}

export default memo(Modal);
