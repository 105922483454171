import React, { memo, useEffect, useState } from 'react';

import { Spinner } from 'reactstrap';
import Select from 'react-select';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

function TenantSelect({ className = '', tenantId, onChangeId, ...props }) {
  const [loading, setLoading] = useState(true);
  const [tenants, setTenants] = useState([]);

  const [tenant, setTenant] = useState(null);

  const loadTenants = async () => {
    try {
      const res = await api.get('dashboard/tenants/names');

      const { tenants } = res?.data;

      setTenants(tenants);

      onChangeId('');
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (option) => {
    setTenant(option);
  };

  useEffect(() => {
    loadTenants();
  }, []);

  useEffect(() => {
    onChangeId(tenant?.tenant_id);
  }, [tenant]);

  useEffect(() => {
    if (tenant?.tenant_id === tenantId) {
      return;
    }

    if (tenantId == '') {
      return setTenant(null);
    }

    const newTenant = tenants.find((tenant) => tenant.tenant_id === tenantId);

    setTenant({ ...newTenant });
  }, [tenantId]);

  if (loading) {
    return <Spinner color="primary" className="d-block mx-auto mb-3" size="sm" />;
  }

  return (
    <Select
      getOptionLabel={(opt) => opt.name}
      getOptionValue={(opt) => opt.tenant_id}
      noOptionsMessage={() => util.t('NO_OPTION')}
      options={tenants}
      placeholder={util.t('SELECT')}
      onChange={(option) => setTenant(option)}
      value={tenant}
      {...props}
    />
  );
}

export default memo(TenantSelect);
