import React, { memo, useRef, useState } from 'react';

import { FormGroup, Row, Col, Label, Spinner, Button } from 'reactstrap';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

function Form() {
  const [loading, setLoading] = useState(false);

  const fileRef = useRef(null);

  const doSave = async (ev) => {
    ev.preventDefault();

    if (loading) {
      return false;
    }

    setLoading(true);

    const file = fileRef.current.files[0];

    const form = new FormData();

    form.append('document', file);

    try {
      const res = await api.post('dashboard/catalogues/import', form);

      const data = res?.data;

      if (data) {
        notification.$s(util.t('CATALOGUE_IMPORT_SUCCESS'), undefined, true);
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner size="lg" className="d-block m-auto" color="primary" />;
  }

  return (
    <form onSubmit={doSave} className="container-fluid p-3">
      <>
        <fieldset>
          <Row form>
            <Col md="6" xl="4">
              <FormGroup>
                <Label htmlFor="best-sellers-file">{util.t('FILE')}</Label>
                <input
                  className="form-control-file"
                  type="file"
                  name="best-sellers-file"
                  ref={fileRef}
                  id="best-sellers-file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  required={true}
                />
              </FormGroup>
            </Col>
          </Row>
        </fieldset>
        <Row className="mt-3">
          <Col>
            <footer className="d-flex">
              <Button type="submit" color="primary">
                {util.t('UPDATE')}
              </Button>
            </footer>
          </Col>
        </Row>
      </>
    </form>
  );
}

export default memo(Form);
