import React, { memo } from 'react';

import debounce from 'lodash.debounce';

import AsyncSelect from 'react-select/async';

import util from '~/assets/util';

import api from '~/services/api';

function MarketplaceCategorySelect({ onChange, ...props }) {
  async function loadOptions(query) {
    try {
      const url = `dashboard/marketplace/categories/search?q=${query}`;
      const res = await api.get(url);

      const { categories } = res?.data;

      if (!categories) {
        return [];
      }

      return categories?.map((item) => {
        return new Object({
          value: item.marketplace_category_id,
          label: item.full_name,
        });
      });
    } catch (e) {
      console.error(e);
    }
  }

  const loadCategories = (query) =>
    new Promise((resolve) => {
      resolve(loadOptions(query));
    });

  const debouncedLoadCategories = debounce((query, callback) => {
    query.length > 2 && loadCategories(query).then((options) => callback(options));
  }, 1000);

  return (
    <AsyncSelect
      {...props}
      placeholder={util.t('SEARCH')}
      loadOptions={debouncedLoadCategories}
      loadingMessage={() => util.t('SEARCHING_THREE_DOTS')}
      noOptionsMessage={() => util.t('NO_OPTION')}
      isClearable
      cacheOptions
      onChange={onChange}
    />
  );
}

export default memo(MarketplaceCategorySelect);
