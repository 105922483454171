import React, { memo, useEffect, useRef, useState } from 'react';

import { FormGroup, Row, Col, Label, Input, Collapse, Spinner } from 'reactstrap';

import InputCellPhone from '~/components/InputCellPhone';
import InputCnpj from '~/components/InputCnpj';
import FormButtons from '~/components/FormButtons';
import StatusSwitch from '~/components/StatusSwitch';

import api from '~/services/api';
import notification from '~/services/notification';

import { Types } from '~/store/constants';

import util from '~/assets/util';

function Form({ id, onClose, onSave }) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);

  const [tenant, setTenant] = useState({
    active: 0,
    card_platform: '',
    facebook_pixel: '',
    franchising_id: 0,
    is_client_drop: 0,
    google_analytics: '',
    google_tagmanager: '',
    is_franchising: 0,
    mail_from_address: '',
    mail_host: '',
    mail_login: '',
    mail_password: '',
    mail_port: '',
    name: '',
    pagseguro_email: '',
    pagseguro_token: '',
    rede_installment_min_value: 0,
    rede_max_installments: '',
    rede_pv: '',
    rede_token: '',
    tenant_id: '',
    winbooks_email: '',
    winbooks_password: '',
    school_booklists: 0,
    winbooks_master: Types.CATAVENTO,

    details: {
      cnpj: '',
      company_name: '',
      email: '',
      phone: '',
      client_url: '',
    }
  });

  const inputNameRef = useRef(null);

  const handleChange = (ev) => {
    const name = ev.target.name;
    
    setTenant({ ...tenant, [name]: ev.target.value, 
      details: { ...tenant.details, [name]: ev.target.value } });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      const form = {
        ...tenant
      };

      const url = `dashboard/tenants/${id ? 'update' : 'create'}`;

      const res = await api.post(url, form);

      const data = res?.data;

      if (data) {
        onSave();
        onClose();

        notification.$s(util.t('SAVED_SUCCESSFULLY'));
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const cancelEdit = () => {
    if (id === 0) {
      return onClose();
    }

    setEditing(true);
  };

  useEffect(() => {
    async function loadTenant() {
      setLoading(true);

      try {
        const res = await api.get(`dashboard/tenants/${id}`);

        const tenant = res?.data;

        if (tenant) {
          setTenant(tenant);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      setEditing(true);

      loadTenant();
    }
  }, []);

  useEffect(() => {
    !editing && inputNameRef?.current?.focus();
  }, [editing]);

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        {id && loading ? (
          <div className="p-5">
            <Spinner size="lg" className="d-block m-auto" color="primary" />
          </div>
        ) : (
          <>
            <fieldset disabled={editing}>
              <Row form>
                <Col md="4" lg="4">
                  <FormGroup>
                    <Label htmlFor="name">{util.t('NAME')}</Label>
                    <Input
                      className="form-control"
                      value={tenant?.name || ''}
                      onChange={handleChange}
                      type="text"
                      name="name"
                      id="name"
                      required
                      autoFocus
                      innerRef={inputNameRef}
                    />
                  </FormGroup>
                </Col>

                <Col md="4" lg="4">
                  <FormGroup>
                    <Label htmlFor="name">Essa loja é uma franquia Nobel?</Label>
                    {!id ? <select
                      className="form-control"
                      name="is_franchising"
                      id="is_franchising"
                      required
                      onChange={handleChange}>
                        <option>Selecione uma opção</option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                    </select> : 
                      <Input
                        className="form-control"
                        value={tenant?.franchising_id > 0 ? 'Sim' : 'Não'}
                        type="text"
                        disabled
                      />}
                  </FormGroup>
                </Col>

                <Col md="4" lg="4">
                  <FormGroup>
                    <Label htmlFor="winbooks_master">{util.t('INTEGRATION_WINDBOOKS')}</Label>
                    <Input
                      className="form-control"
                      value={tenant?.winbooks_master || ''}
                      onChange={handleChange}
                      type="text"
                      name="winbooks_master"
                      id="winbooks_master"
                      required
                      autoFocus
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md="4" lg="4">
                  <FormGroup>
                    <Label htmlFor="active">{util.t('CLIENT_DROP')}</Label>
                    <StatusSwitch
                      disabled={editing}
                      onChange={(value) => setTenant({ ...tenant, is_client_drop: value })}
                      checked={!!tenant.is_client_drop}
                      name="is_client_drop"
                    />
                  </FormGroup>
                </Col>

                <Col md="4" lg="4">
                  <FormGroup>
                    <Label htmlFor="active">{util.t('STATUS')}</Label>
                    <StatusSwitch
                      disabled={editing}
                      onChange={(value) => setTenant({ ...tenant, active: value })}
                      checked={!!tenant.active}
                      name="active"
                    />
                  </FormGroup>
                </Col>

                <Col md="4" lg="4">
                  <FormGroup>
                    <Label htmlFor="school_booklists">{util.t('SCHOOL_LIST')}</Label>
                    <StatusSwitch
                      disabled={editing}
                      id="school_booklists"
                      onChange={(active) => setTenant({ ...tenant, school_booklists: active ? 1 : 0 })}
                      checked={!!tenant.school_booklists}
                      name="school_booklists"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <hr />

              <Row form>
                <Col sm={6} lg={3}>
                  <FormGroup>
                    <Label htmlFor="cnpj">{util.t('LABEL_CNPJ')}</Label>
                    <InputCnpj
                      value={tenant?.details?.cnpj || ''}
                      className="form-control"
                      onChange={handleChange}
                      name="cnpj"
                      id="cnpj"
                      required
                    />
                  </FormGroup>
                </Col>

                <Col sm={6} lg={5}>
                  <FormGroup>
                    <Label htmlFor="companyName">{util.t('COMPANY_NAME')}</Label>
                    <input
                      onChange={handleChange}
                      value={tenant?.details?.company_name || ''}
                      type="text"
                      className="form-control"
                      id="company_name"
                      name="company_name"
                      required={true}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <hr />

              <Row form>
                <Col md="6" lg="4">
                  <FormGroup>
                    <Label htmlFor="email">{util.t('EMAIL')}</Label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      onChange={handleChange}
                      value={tenant?.details?.email}
                      required
                    />
                  </FormGroup>
                </Col>

                <Col sm={6} md={4} lg={3}>
                  <FormGroup>
                    <Label htmlFor="phone">{util.t('CELL_PHONE')}</Label>
                    <InputCellPhone
                      className="form-control"
                      value={tenant?.details?.phone || ''}
                      name="phone"
                      id="phone"
                      onChange={handleChange}
                      required={true}
                    />
                  </FormGroup>
                </Col>

                <Col md="6" lg="4">
                  <FormGroup>
                    <Label htmlFor="client_url">{util.t('URL')}</Label>
                    <input
                      name="client_url"
                      id="client_url"
                      className="form-control"
                      value={tenant?.details?.client_url || ''}
                      onChange={handleChange}
                      required={true}
                    />
                  </FormGroup>
                </Col>
              </Row> 
            </fieldset>
            <hr />
            <Row>
              <Col>
                <footer className="d-flex justify-content-end">
                  <FormButtons
                    isEditing={editing}
                    isSaving={saving}
                    onCancel={cancelEdit}
                    onEdit={() => {
                      setEditing(false);
                    }}
                  />
                </footer>
              </Col>
            </Row>
          </>
        )}
      </form>
    </>
  );
}

export default memo(Form);
