import { Route, BrowserRouter, Switch } from 'react-router-dom';

import Dashboard from './pages/Dashboard';
import ImportBestSellers from './pages/ImportBestSellers';
import Login from '~/pages/Login';
import MarketplaceCategories from './pages/MarketplaceCategories';
import Orders from './pages/Orders';
import Stores from './pages/Stores';
import StoreUsers from './pages/Stores/Users';
import Users from './pages/Users';

const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route component={Login} path="/login" exact />
          <Dashboard>
            <Route component={Orders} path="/" exact />
            <Route component={Orders} path="/orders" exact />
            <Route component={Stores} path="/stores" exact />
            <Route component={StoreUsers} path="/stores/:id/users" />
            <Route component={Users} path="/users" exact />
            <Route component={ImportBestSellers} path="/import-best-sellers" exact />
            <Route component={MarketplaceCategories} path="/marketplace-categories" exact />
          </Dashboard>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Routes;
